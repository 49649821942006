import React from "react"
import SEO from 'components/SEO/seo'
import MainTemplate from 'templates/MainTemplate'
import Contacts from "../components/Contact/Contact";

const Contact = () => (
  <MainTemplate>
       <SEO title="Kontakt" />
  <Contacts />

  </MainTemplate>
)

export default Contact
